<template>
  <div class="pa-16" style="width: 600px">

    <div class="d-flex justify-space-between align-center mb-16">
      <h1 class="text-h4 font-weight-light ">Підключені модулі</h1>
      <v-btn @click="saveChanges" rounded text large  color="teal" class="ml-4 mt-1">
        <v-icon class="mr-2">mdi-check</v-icon>Зберегти</v-btn>
    </div>

    <div v-for="(module,i) in modules" :key="i">
      <div class="d-flex  align-center justify-space-between my-3">
        <div class="d-flex align-center">
          <v-btn icon v-if="module.settings" >
            <v-icon  >mdi-chevron-down</v-icon>
          </v-btn>
          <h3 style="line-height: 1.2" class="font-weight-medium mb-0">{{ module.text }}</h3>
        </div>
        <v-switch v-model="module.active" hide-details />
      </div>
      <v-divider />
      <!-- Module Properties-->
      <div v-if="module.settings">
        <v-sheet class="pa-3 py-3 mt-2" outlined>
          <v-sheet  class="d-flex justify-space-between align-center" v-for="(setting,j) in module.settings" :key="j">
            <span class="caption">{{ setting.text }} : </span>
            <v-switch hide-details v-model="setting.active" class="compact-form mt-n1" small v-if="setting.params.type === 'switch'"></v-switch>
          </v-sheet>

        </v-sheet>
      </div>
    </div>




  </div>
</template>

<script>
import {mapState,mapActions} from "vuex";

export default {
  name: "BusinessDashboardModules",
  data() {
    return {
      loading : false,
      modules : [ ]
    }
  },
  computed : {
    ...mapState('business', ['selectedBusiness']),
  },
  methods : {
    ...mapActions('business', ['UPDATE_MODULES','GET_MODULES']),

    saveChanges(){
      this.loading = true
      let data = {};
      this.modules.forEach((module) => {
        data[module.name] = {}
        data[module.name].active =  module.active ? 1 : 0
        if ( module.settings )  {
          data[module.name].settings = module.settings
        }
      })
      this.UPDATE_MODULES(data).then((out)=>{
        if ( out.result ) {
          this.notify('Налаштування збережено')
          this.$store.state.business.selectedBusiness.modules = JSON.parse(JSON.stringify(this.modules))
        }
        this.loading = false
      }).catch(()=>this.loading = false)
    }
  },
  mounted() {
    this.loading = true
    this.GET_MODULES().then((out)=>{
      if ( out.data) {
        this.modules = out.data
      }
      this.loading = false
    }).catch(()=>{ this.loading = false })

  }
}
</script>

<style scoped>

</style>